import { Basemap } from '@workspaces/types';
import { styles } from './google-maps.styles';
import { getDefaultBoundingBoxForStartup } from '@/plan/metadata/metadata.helper';
import { getAppMetadata } from '@/plan/metadata/metadata';
function getBoundingBoxCenter(bbox) {
    const [minLng, minLat, maxLng, maxLat] = bbox;
    const centerLng = (minLng + maxLng) / 2;
    const centerLat = (minLat + maxLat) / 2;
    return [centerLng, centerLat];
}
export async function getGoogleMapInstance(style, viewState) {
    const mapsLibrary = (await google.maps.importLibrary('maps'));
    const { Map } = mapsLibrary;
    const metadata = getAppMetadata();
    const metaCenter = getBoundingBoxCenter(getDefaultBoundingBoxForStartup(metadata));
    let center = { lat: metaCenter[1], lng: metaCenter[0] };
    let zoom = 4;
    if (viewState !== null) {
        if (viewState.provider === Basemap.Basemap.OpenStreetMaps) {
            center = { lat: viewState.center[1], lng: viewState.center[0] };
        }
        else {
            center = { lat: viewState.center[1], lng: viewState.center[0] };
        }
        zoom = viewState.zoom;
    }
    let styleProperties = {
        center,
        zoom,
        disableDefaultUI: true,
        streetViewControl: false,
    };
    switch (style) {
        case Basemap.Basemap.GoogleMapsPositron:
            styleProperties = {
                ...styleProperties,
                styles: styles.silver,
            };
            break;
        case Basemap.Basemap.GoogleMaps:
            styleProperties = {
                ...styleProperties,
            };
            break;
        case Basemap.Basemap.GoogleMapsSatellite:
            styleProperties = {
                ...styleProperties,
                mapTypeId: google.maps.MapTypeId.SATELLITE,
            };
            break;
        case Basemap.Basemap.GoogleMapsHybrid:
            styleProperties = {
                ...styleProperties,
                mapTypeId: google.maps.MapTypeId.HYBRID,
            };
            break;
        default:
            throw new Error(`Unknown Google Maps style: ${style}. Check metadata options`);
    }
    const map = document.getElementById('map');
    if (!map) {
        throw new Error('Map element not found');
    }
    const googleMapsInstance = new Map(map, styleProperties);
    return googleMapsInstance;
}
