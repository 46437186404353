import { getFieldsWithAtLeastOneFullfilledForCustomPOIsTemplate, getMandatoryFiledsWithDataForCustomPOIsTemplate, getMandatoryHeaderForCustomPOIsTemplate, getOptionalFieldsForCustomPOIsTemplate, getSupportedCountries, isMultiCountry, getCountryISO2ForNotMultiCountry, } from '@/plan/metadata/metadata.helper';
import { Poi } from '@workspaces/types';
import CryptoJS from 'crypto-js';
import { removeWordDelimiters } from './escape';
export const SEPARATOR = '#%%#';
var ErrorMessageKeys;
(function (ErrorMessageKeys) {
    ErrorMessageKeys["MissingName"] = "CUSTOM_POIS_ERROR_FIELD_NAME";
    ErrorMessageKeys["MissingGeom"] = "CUSTOM_POIS_ERROR_FIELD_COORDINATES";
    ErrorMessageKeys["LatOutOfRange"] = "CUSTOM_POIS_ERROR_FIELD_LAT";
    ErrorMessageKeys["LonOutOfRange"] = "CUSTOM_POIS_ERROR_FIELD_LNG";
})(ErrorMessageKeys || (ErrorMessageKeys = {}));
function validateCoordinatesValues(lat, long) {
    const messages = [];
    if (!lat || !long) {
        messages.push(ErrorMessageKeys.MissingGeom);
    }
    const latNumber = Number(lat);
    if (isNaN(latNumber) || latNumber < -90 || latNumber > 90) {
        messages.push(ErrorMessageKeys.LatOutOfRange);
    }
    const longNumber = Number(long);
    if (isNaN(longNumber) || longNumber < -180 || longNumber > 180) {
        messages.push(ErrorMessageKeys.LonOutOfRange);
    }
    return messages;
}
function validateFileEntryAndGenerateCustomPOI(i18n, index, entry) {
    const messages = [];
    const [lat, lon, name] = entry;
    const messageKeys = validateCoordinatesValues(lat, lon);
    for (const key of messageKeys) {
        messages.push(`${i18n('LINE')} ${index}: ${i18n(key)}`);
    }
    const address = entry.slice(3).join(SEPARATOR);
    return {
        messages,
        customPOI: { name, geom: `POINT(${lon} ${lat})`, address },
    };
}
function validateMandatoryHeader(mandatoryHeaders, contentHeaders) {
    const missingColumns = [];
    if (contentHeaders.length === 0) {
        return mandatoryHeaders;
    }
    const contentHeadersTrimmed = contentHeaders.map((header) => header.trim());
    mandatoryHeaders.forEach((header) => {
        const index = contentHeadersTrimmed.indexOf(header);
        if (index === -1) {
            missingColumns.push(header);
        }
    });
    return missingColumns;
}
function getContentSortedForLatLon(meta, content) {
    const contentHeaders = content[0];
    if (!contentHeaders) {
        throw new Error('No content found');
    }
    const mandatoryHeaders = getMandatoryFiledsWithDataForCustomPOIsTemplate(meta, Poi.CustomPOIsImportMode.Coordinates);
    // ['address', 'city', 'postalcode', 'country']
    const optionalHeaders = getOptionalFieldsForCustomPOIsTemplate(meta, Poi.CustomPOIsImportMode.Coordinates);
    const mandatoryHeadersIndexes = mandatoryHeaders.map((header) => contentHeaders.indexOf(header));
    const optionalHeadersIndexes = optionalHeaders.map((header) => contentHeaders.indexOf(header));
    const errors = [];
    const sortedContent = content.map((row, index) => {
        const sortedRow = mandatoryHeadersIndexes.map((index) => row[index]);
        try {
            optionalHeadersIndexes.forEach((index) => {
                if (index !== -1) {
                    sortedRow.push(row[index]);
                }
            });
            const formattedSortedRow = removeWordDelimiters(sortedRow);
            return formattedSortedRow;
        }
        catch (error) {
            errors.push(`${index + 2}`);
            return [];
        }
    });
    return { ok: errors.length === 0, content: sortedContent, errors };
}
function getContentSortedForGeocoding(meta, content) {
    const contentHeaders = content.shift();
    if (!contentHeaders) {
        throw new Error('No content found');
    }
    const mandatoryHeaders = getMandatoryHeaderForCustomPOIsTemplate(meta, Poi.CustomPOIsImportMode.Geocode);
    const mandatoryHeadersIndexes = mandatoryHeaders.map((header) => contentHeaders.indexOf(header));
    const errors = [];
    const sortedContent = content.map((row, index) => {
        const sortedRow = mandatoryHeadersIndexes.map((index) => row[index]);
        try {
            const formattedSortedRow = removeWordDelimiters(sortedRow);
            return formattedSortedRow;
        }
        catch (error) {
            errors.push(`${index + 2}`);
            return [];
        }
    });
    return { ok: errors.length === 0, content: sortedContent, errors };
}
export function validateLatLonCustomPOIs(meta, i18n, contentAsString, maxPOIsPerFile) {
    const mandatoryHeaders = getMandatoryHeaderForCustomPOIsTemplate(meta, Poi.CustomPOIsImportMode.Coordinates);
    const content = contentAsString.split('\n').map((row) => row.split(SEPARATOR));
    const headerMessages = validateMandatoryHeader(mandatoryHeaders, content[0]);
    if (headerMessages.length > 0) {
        const message = `${i18n('CUSTOM_POIS_ERROR_HEADER')} ${headerMessages.join(', ')}`;
        return {
            ok: false,
            messages: [message],
            poisEvaluated: 0,
            customPOIs: [],
        };
    }
    if (content.length > maxPOIsPerFile + 1) {
        return {
            ok: false,
            messages: [`${i18n('CUSTOM_POIS_ERROR_MAX_ELEMENTS')}${maxPOIsPerFile}.`],
            poisEvaluated: 0,
            customPOIs: [],
        };
    }
    const { ok: contentSortedOk, content: contentSorted, errors: contentSortedErrors } = getContentSortedForLatLon(meta, content);
    if (!contentSortedOk) {
        return {
            ok: false,
            messages: contentSortedErrors.map(error => `${i18n('LINE')} ${error}: ${i18n('CUSTOM_POIS_ERROR_UNABLE_TO_PARSE_LINE')}`),
            poisEvaluated: 0,
            customPOIs: [],
        };
    }
    const nameIndex = contentSorted[0].indexOf('name');
    contentSorted.shift();
    const customPOIs = [];
    // In order to try to inform the user about ALL the errors in the file at one time
    const totalMessages = [];
    let poisAutoNaming = 1;
    contentSorted.forEach((entry, i) => {
        if (!entry[nameIndex] || entry[nameIndex].trim().length === 0) {
            entry[nameIndex] = `POI ${poisAutoNaming}`;
            poisAutoNaming++;
        }
        const { messages, customPOI } = validateFileEntryAndGenerateCustomPOI(i18n, i + 2, entry);
        if (messages.length) {
            totalMessages.push(...messages);
        }
        else {
            customPOIs.push(customPOI);
        }
    });
    return {
        ok: totalMessages.length === 0,
        messages: totalMessages,
        poisEvaluated: customPOIs.length,
        customPOIs,
    };
}
function getFieldPositionInTemplate(field, templateMandatoryFields) {
    return templateMandatoryFields.indexOf(field);
}
function validateGeocodingEntries(meta, line, row, supportedCountries, mandatoryFieldsWithData, mandatoryTemplateFields, atLeastOneFieldWithData) {
    const messages = [];
    let country = '';
    mandatoryFieldsWithData.forEach((field) => {
        const fieldPosition = getFieldPositionInTemplate(field, mandatoryTemplateFields);
        if (!row[fieldPosition] || row[fieldPosition].trim().length === 0) {
            messages.push(`Line ${line}: No ${field} set`);
        }
        if (field === 'country') {
            country = row[fieldPosition];
            if (!supportedCountries.includes(row[fieldPosition])) {
                messages.push(`Line ${line}: Value '${row[fieldPosition]}' for country is not supported. Expected 2 characters in uppercase matching available countries`);
            }
        }
    });
    let fieldsWithData = 0;
    const atLeastFieldsCount = atLeastOneFieldWithData.length;
    atLeastOneFieldWithData.forEach((field) => {
        const fieldPosition = getFieldPositionInTemplate(field, mandatoryTemplateFields);
        if (row[fieldPosition].trim().length === 0) {
            fieldsWithData++;
        }
    });
    if (fieldsWithData === atLeastFieldsCount) {
        messages.push(`Line ${line}: One of the following fields must be set: ${atLeastOneFieldWithData.join(', ')}`);
    }
    if (!isMultiCountry(meta)) {
        country = getCountryISO2ForNotMultiCountry(meta);
    }
    return { country, messages };
}
export function validateCustomPOIsAddress(meta, i18n, contentAsString, maxGeocodingPoint, messageMaxGeocodingPoints) {
    const supportedCountries = getSupportedCountries(meta);
    const mandatoryHeaders = getMandatoryHeaderForCustomPOIsTemplate(meta, Poi.CustomPOIsImportMode.Geocode);
    const content = contentAsString.split('\n').map((row) => row.split(SEPARATOR));
    const headerMessages = validateMandatoryHeader(mandatoryHeaders, content[0]);
    const uniqueCountries = new Set();
    if (headerMessages.length > 0) {
        const message = `${i18n('CUSTOM_POIS_ERROR_HEADER')} ${headerMessages.join(', ')}`;
        return {
            ok: false,
            countries: [],
            messages: [message],
            poisEvaluated: 0,
            customPOIsAddress: [],
        };
    }
    if (content.length > maxGeocodingPoint + 1) {
        return {
            ok: false,
            countries: [],
            messages: [messageMaxGeocodingPoints],
            poisEvaluated: 0,
            customPOIsAddress: [],
        };
    }
    const { ok: contentSortedOk, content: contentSorted, errors: contentSortedErrors } = getContentSortedForGeocoding(meta, content);
    if (!contentSortedOk) {
        return {
            ok: false,
            countries: [],
            messages: contentSortedErrors.map(error => `${i18n('LINE')} ${error}: ${i18n('CUSTOM_POIS_ERROR_UNABLE_TO_PARSE_LINE')}`),
            poisEvaluated: 0,
            customPOIsAddress: [],
        };
    }
    const poisEvaluated = contentSorted.length;
    const messages = [];
    let poisAutoNaming = 1;
    const mandatoyFieldsInTemplate = getMandatoryHeaderForCustomPOIsTemplate(meta, Poi.CustomPOIsImportMode.Geocode);
    const mandatoryFieldsWithData = getMandatoryFiledsWithDataForCustomPOIsTemplate(meta, Poi.CustomPOIsImportMode.Geocode);
    const atLeastOneFiledWithData = getFieldsWithAtLeastOneFullfilledForCustomPOIsTemplate(meta, Poi.CustomPOIsImportMode.Geocode);
    const linesHashed = new Map();
    const customPOIsAddress = contentSorted.map((e, index) => {
        const line = index + 2;
        if (!e[0] || e[0].trim().length === 0) {
            e[0] = `POI ${poisAutoNaming}`;
            poisAutoNaming++;
        }
        const name = e[0];
        const lineHashed = CryptoJS.SHA256(name.trim()).toString(CryptoJS.enc.Hex);
        if (linesHashed.has(lineHashed)) {
            let lines = linesHashed.get(lineHashed);
            if (lines) {
                lines.push(line);
            }
            else {
                lines = [line];
            }
            messages.push(`Line ${line}: The same POI name has been entered multiple times in lines ${lines.join(', ')}`);
        }
        else {
            linesHashed.set(lineHashed, [line]);
        }
        const { messages: partialMessages, country } = validateGeocodingEntries(meta, line, e, supportedCountries, mandatoryFieldsWithData, mandatoyFieldsInTemplate, atLeastOneFiledWithData);
        if (partialMessages.length > 0) {
            messages.push(...partialMessages);
        }
        if (country) {
            uniqueCountries.add(country);
        }
        return {
            name: e[0],
            address: e[1] + SEPARATOR + e[2] + SEPARATOR + e[3],
            country: e[4],
        };
    });
    const countries = Array.from(uniqueCountries);
    return {
        ok: messages.length === 0,
        countries,
        messages,
        poisEvaluated,
        customPOIsAddress,
    };
}
