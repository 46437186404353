import { getInstance as getAuth0ServiceInstance } from '@/auth/index';
import BrowserEnvironmentResolver from './environment.helper';
import { getCartoMeInfo } from './carto.helper';
import { getPresetCountriesForUser } from './country.helper';
import { getAppMetadata } from '@/plan/metadata/metadata';
import UserPermissionManager from './permissions/permissions.manager';
import { getRole } from './auth.helper';
import { Auth } from '@workspaces/types';
import { isPackagagesFeatureEnabled } from '@/plan/metadata/metadata.helper';
import { getUserAgencyId } from './agency.helper';
import { ADMIN_AGENCY } from '@/constants';
import { getRoleForUser } from './testing/auth.helper';
async function getRoleFromAuth0(metadata) {
    console.debug('🟧 Auth0: Getting role from Auht0');
    const auth0Instance = getAuth0ServiceInstance();
    const carto3Token = auth0Instance.getAccessTokenCartoV3();
    const agencyId = await getUserAgencyId(metadata, BrowserEnvironmentResolver.getInstance(), carto3Token, true);
    if (agencyId) {
        if (agencyId === ADMIN_AGENCY) {
            return Auth.Role.Admin;
        }
        return Auth.Role.User;
    }
    return Auth.Role.Unknown;
}
export async function initializeUserPermissionManager() {
    const environmentResolver = BrowserEnvironmentResolver.getInstance();
    const metadata = getAppMetadata();
    const auth0ServiceInstance = getAuth0ServiceInstance();
    let userName = Auth.Role.Anonymous;
    let userRole = Auth.Role.Anonymous;
    let countryIds = [];
    if (!auth0ServiceInstance) {
        console.debug('👩🏻‍🦰 AuthService: User not authenticated. Suppose we are in shared plan');
    }
    else {
        userName = auth0ServiceInstance.getUser();
        if (environmentResolver.isTesting()) {
            console.debug('👩🏻‍🦰 🕵️‍♂️ Initializaing permission manager in testing mode');
            countryIds = [1, 6, 9];
            userRole = getRoleForUser(userName);
        }
        else {
            const cartoMe = await getCartoMeInfo(environmentResolver, auth0ServiceInstance.getAccessTokenCartoV3());
            countryIds = await getPresetCountriesForUser(getAppMetadata(), environmentResolver, cartoMe);
            if (isPackagagesFeatureEnabled(metadata)) {
                userRole = await getRoleFromAuth0(metadata);
            }
            else {
                userRole = getRole(metadata, cartoMe);
            }
        }
        auth0ServiceInstance.setRole(userRole);
        console.debug(`👩🏻‍🦰 AuthService - User:  ${userName}`);
        console.debug(`👩🏻‍🦰 AuthService - Role:  ${userRole}`);
        auth0ServiceInstance.setUserCountries(countryIds);
        console.debug('👩🏻‍🦰 AuthService - User countries: ', countryIds);
    }
    UserPermissionManager.initialize({ name: userName, role: userRole });
}
