import ResolverBasic from './resolver-basic';
/**
 * 📢 No roles are defined
 */
class PermissionResolverUS extends ResolverBasic {
    canUploadCustomPOIs() {
        return true;
    }
    canEditAnyCustomPOIGroup() {
        return false;
    }
    permitComments() {
        return false;
    }
    canSavePlan(planOwner, planId, isPlanPublic) {
        console.debug(`canSavePlan: planOwner: ${planOwner}, planId: ${planId}, isPlanPublic: ${isPlanPublic}`);
        if (!planId) {
            console.debug('            canSavePlan: planId is not defined');
            return true;
        }
        console.debug(`            canSavePlan: ${this.getName()} === ${planOwner}`);
        return this.getName() === planOwner;
    }
    canSavePlanAsPublic() {
        return true;
    }
    canDeletePlan(plan) {
        const planOwner = plan.created_by;
        return this.getName() === planOwner;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canDeleteCustomGeoboundary(owner) {
        return false;
    }
    canDeleteCustomPOIGroup(owner) {
        return this.isOwnerOrAdmin(owner);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canUpdateCustomPOIGroup(owner) {
        return this.isOwnerOrAdmin(owner);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canAdminEditPlan(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canEditPlanPrice(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canBookPlanPackage(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canConfirmBookingPlanPackageByAdmin(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canConfirmBookingPlanPackageByAgency(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canFinalizeConfirmBookingPlanPackageByAdmin(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canCancelPlanPackageByAdmin(plan) {
        return false;
    }
    canShowWidgetUploadAssets() {
        return true;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canEditPlanPackageDates(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canEditPlanPackagePrice(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canEditPlanPackageCustomPanels(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canEditPlanPackagePanelTypes(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canEditPlanPackagePackages(plan) {
        return false;
    }
}
export default PermissionResolverUS;
