import Vue from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AppShell from './app-shell/AppShell.vue';
import router from './router';
import store from './store';
import './styles/style.scss';
import i18n from './i18n';
import authService from './api/authService';
// Import the Auth0 configuration
import authConfig from '../auth_config.json';
import { Auth0Plugin } from './auth';
import { getRedirectUri, getRequireComponent } from './constants';
import { initializeFirebaseApp } from '@workspaces/services';
import { getAppMetadata } from './plan/metadata/metadata';
import { getAccessTokenForSharedPlan, getBasemapsConfig, getCartoAppConfiguration, getCartoConfigurationSSO, getLayersPrecedence, } from './plan/metadata/metadata.helper';
import { TrackJS } from 'trackjs';
import { setDefaultCredentials } from '@deck.gl/carto/typed';
import BrowserEnvironmentResolver from './helpers/environment.helper';
import { initializeUserPermissionManager } from '@/helpers/auth-browser.helper';
import SharedPlanPasswordManager from '@/helpers/sharedPlanPassword.helper';
import { Loader } from '@googlemaps/js-api-loader';
import { isGoogleMapsBasemap } from './helpers/google-maps.helper';
import { setupZIndexValuesForLayers } from '@/layers/layers-z-index.helper';
(async () => {
    try {
        console.debug('🟩 Preparing APP ...');
        const environmentResolver = BrowserEnvironmentResolver.getInstance();
        const isTesting = environmentResolver.isTesting();
        console.debug(`😈 Testing mode: ${isTesting}`);
        const metadata = getAppMetadata();
        console.debug(`🟩 Flavour: ${metadata.flavour}`);
        window.document.title = metadata.app_title;
        // TrackJS configuration ----------
        if (isTesting ||
            location.host.indexOf('localhost') === 0 ||
            location.host.indexOf('127.0.0.1') === 0) {
            console.debug('🟩 TrackJS: disabled');
        }
        else {
            const version = `${process.env.VUE_APP_ENV}-${metadata.flavour}`;
            console.debug(`🟩 TrackJS: enabled | version: ${version}`);
            TrackJS.install({
                token: 'cf7a7cd8411140689ea94e92d9fba842',
                application: 'carto-ps-radarview-us',
                console: { display: true, watch: ['error', 'warn'] },
                network: { error: false },
                version,
            });
        }
        const layersPrecedence = getLayersPrecedence(metadata);
        console.debug('🗺️ Setting up layers precendece, from top to bottom: ', layersPrecedence);
        setupZIndexValuesForLayers(layersPrecedence);
        // Load Google Maps API
        const basemapConfig = getBasemapsConfig(metadata);
        if (basemapConfig) {
            const googleMapsIsAvailable = basemapConfig.options.find((e) => isGoogleMapsBasemap(e.id));
            if (googleMapsIsAvailable) {
                console.debug('🟩 Google Maps: loading ...');
                if (!googleMapsIsAvailable.apiKey) {
                    throw new Error('Google Maps API key is required, as it is configured as a basemap. Check metadata file');
                }
                const environment = environmentResolver.getEnvironment();
                const loader = new Loader({
                    apiKey: googleMapsIsAvailable.apiKey[environment],
                    version: 'weekly',
                    language: 'en',
                    libraries: ['places'],
                });
                await loader.importLibrary('maps');
                console.debug('🟩 Google Maps: loaded');
            }
        }
        // Carto configuration ---------
        const cartoAppConfiguration = getCartoAppConfiguration(metadata, environmentResolver);
        console.debug('🟩 CARTO app configuration', cartoAppConfiguration);
        const url = window.location.href;
        const queryParams = new URLSearchParams(url);
        const sharedPlan = queryParams.get('shared');
        // Auth0 configuration ---------
        if (sharedPlan !== 'true') {
            console.debug('🟧 Auth0: registering plugin');
            const redirectUri = getRedirectUri();
            Vue.use(Auth0Plugin, {
                domain: authConfig.domain,
                clientId: cartoAppConfiguration.clientId,
                redirectUri,
                cacheLocation: authConfig.cacheLocation,
                scopes: authConfig.scopes,
                audience: authConfig.audience,
                organization: getCartoConfigurationSSO(metadata, environmentResolver),
                onRedirectCallback: (appState) => {
                    router.push(appState && appState.targetUrl
                        ? appState.targetUrl
                        : window.location.pathname);
                },
            });
        }
        else {
            console.debug('🟩 Auth0: Not registering plugin [Shared plan]');
            const sharedPlanId = queryParams.get('planId');
            if (sharedPlanId === null) {
                throw new Error('Shared plan id is required');
            }
            const sharedPlanPasswordManager = SharedPlanPasswordManager.getInstance();
            await sharedPlanPasswordManager.init(metadata, environmentResolver, sharedPlanId);
            const shareTileToken = getAccessTokenForSharedPlan(environmentResolver);
            setDefaultCredentials({
                apiVersion: 'v3',
                apiBaseUrl: getAppMetadata().base_url,
                accessToken: shareTileToken,
            });
        }
        // Vue2 configuration
        Vue.config.productionTip = false;
        // Ignore Airship components
        Vue.config.ignoredElements = [/as-\w+/];
        // Error handler
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        window.onerror = (message, source, lineno, colno, error) => {
            // message: error message (string). Available as event (sic!) in HTML onerror="" handler.
            // source: URL of the script where the error was raised (string)
            // lineno: Line number where error was raised (number)
            // colno: Column number for the line where the error occurred (number)
            // error: Error Object (object)
            onError({
                err: message,
                source,
                info: `in line ${lineno}:${colno}`,
            });
        };
        Vue.config.errorHandler = (err, vm, info) => {
            // err: error trace
            // vm: component in which error occured
            // info: Vue specific error information such as lifecycle hooks, events etc.
            onError({
                err,
                source: vm,
                info,
            });
        };
        const requireComponent = getRequireComponent();
        requireComponent.keys().forEach((fileName) => {
            // Get component config
            const componentConfig = requireComponent(fileName);
            const fileNameSplitted = fileName.split('/');
            const chunk = fileNameSplitted.pop() || '';
            const componentName = chunk.replace(/\.\w+$/, '');
            // Register component globally
            Vue.component(componentName, componentConfig.default || componentConfig);
        });
        // Firebase configuration ---------
        initializeFirebaseApp(metadata, environmentResolver);
        if (sharedPlan !== 'true') {
            console.debug('🟧 Login ...');
            await authService.login();
        }
        else {
            console.debug('🟧 Login: Not logging in [Shared plan]');
            await initializeUserPermissionManager();
        }
        // Running Vue App ---------
        new Vue({
            router,
            store,
            i18n,
            render: (h) => h(AppShell),
        }).$mount('#app');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        function onError({ err, source, info }) {
            /* eslint-disable */
            console.error('Error: ', err.message, err);
            console.error('Source: ', source);
            console.error('Extra info: ', info);
            /* eslint-enable */
        }
    }
    catch (err) {
        console.error(err);
        process.exit(1);
    }
})();
export const bus = new Vue();
