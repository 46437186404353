import { Environment } from '@workspaces/types';
// prettier-ignore
export default class BrowserEnvironmentResolver {
    environment;
    testing;
    sharePlan;
    static instance;
    isSharedPlan() {
        try {
            const url = new URL(window.location.href);
            if (url.searchParams.has('shared')) {
                const shared = url.searchParams.get('shared');
                if (shared === 'true') {
                    return { sharing: true, id: url.searchParams.get('planId') ?? 'unable_to_retreive' };
                }
            }
        }
        catch (error) {
            console.warn('Error parsing URL. Assuming no shared plan');
        }
        return { sharing: false, id: '' };
    }
    constructor() {
        this.environment = process.env.VUE_APP_ENV;
        this.testing = process.env.VUE_APP_TESTING;
        this.sharePlan = this.isSharedPlan();
    }
    getLocation() {
        throw new Error('BrowserEnvironmentResolver has no location');
    }
    static getInstance() {
        if (!BrowserEnvironmentResolver.instance) {
            BrowserEnvironmentResolver.instance = new BrowserEnvironmentResolver();
        }
        return BrowserEnvironmentResolver.instance;
    }
    getEnvironment() {
        switch (this.environment) {
            case 'development':
                return Environment.Environment.Development;
            case 'staging':
                return Environment.Environment.Staging;
            case 'production':
                return Environment.Environment.Production;
            default:
                throw new Error(`Unknown environment for value [${this.environment}]. Check .env file and VUE_APP_ENV variable`);
        }
    }
    getEnvironmentForMetadata() {
        switch (this.environment) {
            case 'development':
                return 'dev';
            case 'staging':
                return 'staging';
            case 'production':
                return 'prod';
            default:
                throw new Error(`Unknown environment for value [${this.environment}]. Check .env file and VUE_APP_ENV variable`);
        }
    }
    getEnvironmentForBQ() {
        const environment = this.getEnvironment();
        return this.isTesting() ? `${environment}_test` : environment;
    }
    getEnvironmentVariable(name) {
        const environmentVaraibleValue = process.env[name];
        if (environmentVaraibleValue) {
            return environmentVaraibleValue;
        }
        throw new Error(`Environment variable ${name} not found`);
    }
    isTesting() {
        if (this.testing === undefined) {
            console.warn('VUE_APP_TESTING environment variable not found. Assuming false');
        }
        return this.testing === 'true';
    }
    isSharePlan() {
        return this.sharePlan;
    }
}
