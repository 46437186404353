import sharedPlanService from '@/services/sharedPlan.service';
// prettier-ignore
export default class SharedPlanPasswordManager {
    static instance;
    password;
    passwordRequired;
    alreadyInit;
    isPlanActive;
    constructor() {
        this.alreadyInit = false;
        this.password = undefined;
        this.passwordRequired = true;
        this.isPlanActive = false;
    }
    async init(meta, environment, sharedPlanId) {
        if (this.alreadyInit) {
            throw new Error('🔴 SharedPlanPasswordManager --> Already initialized. Cannot be intialized more than once');
        }
        const sharedPlanState = await sharedPlanService.isPasswordRequired(meta, environment, sharedPlanId);
        this.passwordRequired = sharedPlanState.isPasswordRequired;
        this.isPlanActive = sharedPlanState.isActive;
    }
    static getInstance() {
        if (!SharedPlanPasswordManager.instance) {
            SharedPlanPasswordManager.instance = new SharedPlanPasswordManager();
        }
        return SharedPlanPasswordManager.instance;
    }
    isPasswordRequired() {
        return this.passwordRequired;
    }
    isActive() {
        return this.isPlanActive;
    }
    getPassword() {
        const isPasswordRequired = this.isPasswordRequired();
        if (!isPasswordRequired) {
            return undefined;
        }
        if (!this.password) {
            throw new Error('🔴 SharedPlanPasswordManager --> Trying to retreive a password but NO password has been provided');
        }
        return this.password;
    }
    setPassword(password) {
        this.password = password;
    }
}
