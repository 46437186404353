import { AllLayersPrecedence } from '@/layers/constant';
import { Metadata, Audience, Media, Proximity, Export, Package, Auth, Poi, Basemap, } from '@workspaces/types';
export const METADATA = {
    app_title: 'Clear Channel Finland',
    flavour: 'FI',
    flavour_testing: 'Test_FI',
    logo: 'logo-radarview-fi-big.svg',
    terms_of_use_url: '/terms-of-use/fi/terms-of-use.pdf',
    login_page_setup: {
        welcome_message: 'LaunchPAD Direct is a self-service platform for media buyers, provided by Clear Channel Finland.',
        contact_message: "Don't have an account yet? ",
        contact_email: 'campaigns@clearchannel.fi',
        show_terms_of_use: true,
    },
    app_config: {
        carto_client_id: {
            dev: '4jKkZRsnKfM570uuehVt9Ya7KCg0jHNg',
            staging: 'SASnhMbpOuhVncAcCjRkCAckYcCXoart',
            prod: 'JT10ViHNJdtUkqFWJd1sfRMF7Q2SpT20',
        },
        carto_organization: 'org_fqJHP2hdSbsPofl5',
        carto_organization_required_for_testing: true,
        front_url: [
            'https://carto-ps-radarview-fi-dev.web.app/.*',
            'https://carto-ps-radarview-fi-stg.web.app/.*',
            'https://radarview-fi-dev.carto.solutions/.*',
            'https://radarview-fi-staging.carto.solutions/.*',
            'https://radarview-fi.carto.solutions/.*',
            'https://direct.clearchannel.fi/.*',
        ],
        firebase: {
            dev: {
                apiKey: 'AIzaSyDwA1u6yjw3P5zcPHx0ahaOOQUeV2Hebh4',
                authDomain: 'carto-ps-radarview-fi-dev.firebaseapp.com',
                projectId: 'carto-ps-radarview-fi-dev',
                storageBucket: 'carto-ps-radarview-fi-dev.appspot.com',
                messagingSenderId: '116831714104',
                appId: '1:116831714104:web:2aa7b0bcd8436c0ce3dfcd',
            },
            staging: {
                apiKey: 'AIzaSyBWlPDu_IfvsatKRyPZ_1DKlvOrcXD0LO4',
                authDomain: 'carto-ps-radarview-fi-stg.firebaseapp.com',
                projectId: 'carto-ps-radarview-fi-stg',
                storageBucket: 'carto-ps-radarview-fi-stg.appspot.com',
                messagingSenderId: '17924356518',
                appId: '1:17924356518:web:59de8f14a8b223df45f767',
            },
            prod: {
                apiKey: 'AIzaSyCPHRv5ZWcvrsGq7Txs9Wrb-eO7ljk_cL4',
                authDomain: 'carto-ps-radarview-fi-pro.firebaseapp.com',
                projectId: 'carto-ps-radarview-fi-pro',
                storageBucket: 'carto-ps-radarview-fi-pro.appspot.com',
                messagingSenderId: '933117725912',
                appId: '1:933117725912:web:a7475c79207feeca351bad',
            },
        },
        remote_functions: {
            firebase_token: {
                dev: 'https://europe-west1-carto-ps-radarview-fi-dev.cloudfunctions.net/default-function',
                staging: 'https://europe-west1-carto-ps-radarview-fi-stg.cloudfunctions.net/default-function',
                prod: 'https://europe-west1-carto-ps-radarview-fi-pro.cloudfunctions.net/default-function',
            },
            audit: {
                dev: 'https://europe-west1-carto-ps-radarview-fi-dev.cloudfunctions.net/reports',
                staging: 'https://europe-west1-carto-ps-radarview-fi-stg.cloudfunctions.net/reports',
                prod: 'https://europe-west1-carto-ps-radarview-fi-pro.cloudfunctions.net/reports',
            },
            big_query_custom_POIS: {
                dev: 'https://europe-west1-carto-ps-radarview-fi-dev.cloudfunctions.net/custom-pois',
                staging: 'https://europe-west1-carto-ps-radarview-fi-stg.cloudfunctions.net/custom-pois',
                prod: 'https://europe-west1-carto-ps-radarview-fi-pro.cloudfunctions.net/custom-pois',
            },
            aida: {
                dev: 'https://europe-west1-carto-ps-radarview-fi-dev.cloudfunctions.net/aida',
                staging: 'https://europe-west1-carto-ps-radarview-fi-stg.cloudfunctions.net/aida',
                prod: 'https://europe-west1-carto-ps-radarview-fi-pro.cloudfunctions.net/aida',
            },
            share_plan: {
                dev: 'https://europe-west1-carto-ps-radarview-fi-dev.cloudfunctions.net/plan',
                staging: 'https://europe-west1-carto-ps-radarview-fi-stg.cloudfunctions.net/plan',
                prod: 'https://europe-west1-carto-ps-radarview-fi-pro.cloudfunctions.net/plan',
            },
            isochrones: {
                dev: 'https://europe-west1-carto-ps-radarview-fi-dev.cloudfunctions.net/isochrones',
                staging: 'https://europe-west1-carto-ps-radarview-fi-stg.cloudfunctions.net/isochrones',
                prod: 'https://europe-west1-carto-ps-radarview-fi-pro.cloudfunctions.net/isochrones',
            },
            auth0: {
                dev: 'https://europe-west1-carto-ps-radarview-fi-dev.cloudfunctions.net/generic/auth0/',
                staging: 'https://europe-west1-carto-ps-radarview-fi-stg.cloudfunctions.net/generic/auth0/',
                prod: 'https://europe-west1-carto-ps-radarview-fi-pro.cloudfunctions.net/generic/auth0/',
            },
            custom_geoboundaries: {
                dev: 'https://europe-west1-carto-ps-radarview-fi-dev.cloudfunctions.net/custom-geoboundaries',
                staging: 'https://europe-west1-carto-ps-radarview-fi-stg.cloudfunctions.net/custom-geoboundaries',
                prod: 'https://europe-west1-carto-ps-radarview-fi-pro.cloudfunctions.net/custom-geoboundaries',
            },
            signed_urls: {
                dev: 'https://europe-west1-carto-ps-radarview-fi-dev.cloudfunctions.net/generic/signed-url',
                staging: 'https://europe-west1-carto-ps-radarview-fi-stg.cloudfunctions.net/generic/signed-url',
                prod: 'https://europe-west1-carto-ps-radarview-fi-pro.cloudfunctions.net/generic/signed-url',
            },
        },
        // Buckets must be created under the corresponding project associated with the environment.
        // The full path of the bucket is: gs://<project_name>-<bucket_name>. In metadata only bucket_name is needed.
        buckets: {
            attachments: {
                dev: 'comment-attachments',
                staging: 'comment-attachments',
                prod: 'comment-attachments',
            },
        },
        basemaps: {
            enable: false,
            default: Basemap.Basemap.OpenStreetMaps,
            options: [
                { id: Basemap.Basemap.OpenStreetMaps, name: 'Open Street Maps' },
                {
                    id: Basemap.Basemap.GoogleMaps,
                    name: 'Google Maps',
                    apiKey: { dev: '', stg: '', pro: '' },
                },
            ],
        },
    },
    roles: [],
    bq_project: {
        dev: 'carto-ps-radarview-fi-dev',
        staging: 'carto-ps-radarview-fi-stg',
        prod: 'carto-ps-radarview-fi-pro',
    },
    carto_events_url: 'https://workspace-gcp-europe-west1.app.carto.com/events',
    base_url: 'https://gcp-europe-west1.api.carto.com',
    auth0_user_key: 'http://app.carto.com/email',
    auth0_account_id_key: 'http://app.carto.com/account_id',
    connection: 'radarview_fi',
    analytics_toolbox_base_url: 'carto-un-fi',
    languages: {
        enable: false,
        options: [
            { id: 'en', name: 'ENGLISH', locale: 'en-gb' },
            { id: 'fi', name: 'FINNISH' },
        ],
    },
    countries: [
        {
            id: 8,
            id_legacy: 213,
            name: 'Finland',
            iso2: 'FI',
            iso3: 'FIN',
            meta_region: [
                { type: 'lau', name: 'Kunnat' },
                { type: 'nuts1', name: 'Manner-Suomi, Ahvenananmaa' },
                { type: 'nuts2', name: 'Suuralueet' },
                { type: 'nuts3', name: 'Maakunnat' },
            ],
        },
    ],
    local_storage_keys: {
        lang: 'CCS_lang',
        auth: 'CCS_auth',
        firebase_token: 'CCS_firebaseToken',
    },
    units: {
        distance: 'meters',
        currency: 'EUR',
        currency_symbol: '€',
    },
    data_model: {
        regions: {
            table_name: 'regions',
            regions_hierarchy: {
                region_1: {
                    id: 1,
                    name: 'country',
                    order: 1,
                    countries: [],
                    geom: true,
                },
                region_2: { id: 2, name: 'nuts1', order: 4, countries: [], geom: true },
                region_3: { id: 3, name: 'nuts2', order: 5, countries: [], geom: true },
                region_4: { id: 4, name: 'nuts3', order: 6, countries: [], geom: true },
                region_5: { id: 5, name: 'lau', order: 7, countries: [], geom: true },
                region_6: {
                    id: 6,
                    name: 'regionccf',
                    order: 8,
                    countries: [5],
                    geom: true,
                },
                region_7: {
                    id: 7,
                    name: 'agglomerationccf',
                    order: 9,
                    countries: [5],
                    geom: true,
                },
                region_8: {
                    id: 8,
                    name: 'unite_urban',
                    order: 10,
                    countries: [5],
                    geom: true,
                },
                region_9: { id: 9, name: 'city', order: 2, countries: [], geom: false },
                region_10: {
                    id: 10,
                    name: 'zip_code',
                    order: 11,
                    countries: [],
                    geom: false,
                },
                region_11: {
                    id: 11,
                    name: 'ad_region',
                    order: 3,
                    countries: [],
                    geom: false,
                },
                region_15: {
                    id: 15,
                    name: 'file',
                    order: 15,
                    countries: [],
                    geom: true,
                },
            },
            partitioned_column: 'dma',
            columns: [
                {
                    id: 'id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    autocomplete: true,
                    display: false,
                },
                {
                    id: 'name',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    autocomplete: true,
                    display: false,
                },
                {
                    id: 'normalized_name',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    autocomplete: true,
                    display: false,
                },
                {
                    id: 'type',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    autocomplete: true,
                    display: false,
                },
                {
                    id: 'geom',
                    type: Metadata.DataModelFieldTypes.Geography,
                    visible: false,
                    filterable: true,
                    autocomplete: false,
                    display: false,
                },
                {
                    id: 'country_id',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    autocomplete: true,
                    display: false,
                },
                {
                    id: 'iso2',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    autocomplete: true,
                    display: false,
                },
                {
                    id: 'iso3',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    autocomplete: true,
                    display: false,
                },
                {
                    id: 'parent',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    autocomplete: false,
                    display: false,
                },
            ],
        },
        custom_pois_address_tmp: {
            table_name: 'custom_pois_address_tmp',
            columns: [
                {
                    id: 'address',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'name',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'carto_geocode_metadata',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
            ],
        },
        assets: {
            table_name: 'assets',
            chunk_size: 2500,
            cache: {
                dbName: 'Radarview_FI',
                store: 'assetsStore',
                key: 'assets',
                key_last_update: 'lastUpdate',
                version: 1,
            },
            cluster_icon: 'cluster-20',
            columns: [
                {
                    id: 'id',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                    background: true,
                },
                {
                    id: 'geom',
                    type: Metadata.DataModelFieldTypes.Geography,
                    visible: false,
                    filterable: true,
                    display: false,
                    startup: false,
                },
                {
                    id: 'name',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: false,
                    background: true,
                },
                {
                    id: 'internal_panel_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'packages',
                    type: Metadata.DataModelFieldTypes.ArrayNumbers,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'external_panel_id',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'size',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: false,
                    display: true,
                },
                {
                    id: 'impressions',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'is_digital',
                    type: Metadata.DataModelFieldTypes.Boolean,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'pictureurl',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: false,
                    display: true,
                },
                {
                    id: 'l1panelclass_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'l1panelclass',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: false,
                    background: true,
                },
                {
                    id: 'l2product_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'l2_product',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: false,
                    background: true,
                },
                {
                    id: 'l3subproduct_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'l3subproduct',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: false,
                    background: true,
                },
                {
                    id: 'l4_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'l4',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: false,
                    background: true,
                },
                {
                    id: 'l5_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'l5',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: false,
                    background: true,
                },
                {
                    id: 'l6_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'l6',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: false,
                    background: true,
                },
                {
                    id: 'l7_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'l7',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: false,
                    background: true,
                },
                {
                    id: 'l8_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
                {
                    id: 'l8',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: false,
                    background: true,
                },
                {
                    id: 'l9_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                    startup: true,
                },
                {
                    id: 'l9',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    background: true,
                },
                {
                    id: 'latitude',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: false,
                    display: true,
                    startup: true,
                },
                {
                    id: 'longitude',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: false,
                    display: true,
                    startup: true,
                },
                {
                    id: 'address',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: false,
                    display: true,
                    background: true,
                },
                {
                    id: 'business_unit_id',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: false,
                    display: false,
                    background: true,
                },
                {
                    id: 'address2',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: false,
                    display: true,
                },
                {
                    id: 'angle_to_map_north',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: false,
                    display: true,
                    background: true,
                },
                {
                    id: 'city_name',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    background: true,
                },
                {
                    id: 'zip_code_name',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: true,
                    startup: true,
                },
            ],
        },
        custom_pois: {
            table_name: 'custom_pois',
            columns: [
                {
                    id: 'name',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'pois',
                    type: Metadata.DataModelFieldTypes.Array,
                    visible: false,
                    filterable: false,
                    display: false,
                },
                {
                    id: 'ishared',
                    type: Metadata.DataModelFieldTypes.Boolean,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'user_email',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'category',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'subcategory',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
            ],
        },
        pois: {
            table_name: 'pois',
            autocomplete: 'pois',
            autocomplete_locations: 'pois_autocomplete',
            tileset: 'pois_tileset',
            columns: [
                {
                    id: 'id',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'location_name',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'top_category',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'sub_category',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'naics_code',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'geom',
                    type: Metadata.DataModelFieldTypes.Geography,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'street_address',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'category_tags',
                    type: Metadata.DataModelFieldTypes.Array,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'state_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'state_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'dmas_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'congressional_distric_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'cbsa_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'county_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'city_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'zip_code_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'category_tags',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'country_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
            ],
        },
        audiences: {
            table_name: 'audiences',
            columns: [
                {
                    id: 'id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'year',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'month',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'panel',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'market',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'index',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'asset_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'clazz_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'county_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'month_unique_counts',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'month_contact_counts',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: false,
                    filterable: true,
                    display: false,
                },
            ],
        },
        audiences_classes: {
            table_name: 'audiences_classes',
            columns: [
                {
                    id: 'year_month_or_week',
                    type: Metadata.DataModelFieldTypes.Date,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'country_iso3',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'uid',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'class_group',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'audience',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'attr1',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'attr2',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'attr3',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'attr4',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'attr5',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'attr6',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'name',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'type',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: true,
                    filterable: true,
                    display: false,
                },
            ],
        },
        panelcounts: {
            table_name: 'panel_counts',
            columns: [],
        },
        pois_isochrones: {
            table_name: 'pois_isochrones',
            columns: [],
        },
        shared_plans_assets: {
            table_name: 'shared_plan_results',
            columns: [],
        },
        custom_geoboundaries: {
            table_name: 'custom_geoboundaries',
            columns: [],
        },
        packages: {
            table_name: 'packages',
            cache: {
                dbName: 'Radarview_FI',
                store: 'packagesStore',
                key: 'packages',
                key_last_update: 'packagesLastUpdate',
                version: 2,
            },
            columns: [
                {
                    id: 'package_id',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'package',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'gross_price',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'posting',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'production',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'contacts',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'panels',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'gross_cpt',
                    type: Metadata.DataModelFieldTypes.Number,
                    visible: true,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'digital',
                    type: Metadata.DataModelFieldTypes.Boolean,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'l1',
                    type: Metadata.DataModelFieldTypes.String,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'l2',
                    type: Metadata.DataModelFieldTypes.ArrayStrings,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'l3',
                    type: Metadata.DataModelFieldTypes.ArrayStrings,
                    visible: false,
                    filterable: true,
                    display: false,
                },
                {
                    id: 'l4',
                    type: Metadata.DataModelFieldTypes.ArrayStrings,
                    visible: false,
                    filterable: true,
                    display: false,
                },
            ],
        },
        packages_availability: {
            table_name: 'packages_availability',
            columns: [],
        },
        assets_optimize_distribution: {
            table_name: 'assets_optimize_distribution',
            columns: [],
        },
    },
    features: {
        permission_resolver: Auth.PermissionResolver.FI,
        comments: {
            enable: true,
            attachments: {
                enable: true,
                max_size_mb: 20,
            },
            show_toolbar_summary_plans_comments: true,
            realtime_reaction_in_seconds: 5,
        },
        custom_geoboundaries: {
            enable: false,
            upload_file: false,
            upload_file_max_size_mb: 1,
            save_group_from_plan: false,
        },
        packages: {
            enable: true,
            expiration: {
                booking_confirmation: 336 * 60 * 60 * 1000,
                cancellation_time_limit: 48 * 60 * 60 * 1000,
            },
            availability_range: [
                {
                    value: 90,
                    text: 'HIGH_RANGE',
                    color: '#76cc85',
                    description: 'Campaign can most likely be delivered',
                },
                {
                    value: 40,
                    text: 'MEDIUM_RANGE',
                    color: '#f0a65e',
                    description: 'Campaign can most likely be delivered with small adjustments',
                },
                {
                    value: 0,
                    text: 'LOW_RANGE',
                    color: '#fb837d',
                    description: 'High demand',
                },
            ],
            states_style: {
                [Package.State.Draft]: { background: '#c9cbd1', foreground: '#383d4c' },
                [Package.State.BookingRequest]: {
                    background: '#fbcf7d',
                    foreground: '#383d4c',
                },
                [Package.State.BookingConfirmedByAdmin]: {
                    background: '#fbcf7d',
                    foreground: '#383d4c',
                },
                [Package.State.BookingConfirmedByAgency]: {
                    background: '#76cc85',
                    foreground: '#ffffff',
                },
                [Package.State.ReBooking]: {
                    background: '#fbcf7d',
                    foreground: '#383d4c',
                },
                [Package.State.Expired]: {
                    background: '#fb837d',
                    foreground: '#ffffff',
                },
                [Package.State.Booked]: {
                    background: '#296f34',
                    foreground: '#ffffff',
                },
                [Package.State.Cancelled]: {
                    background: '#cb433c',
                    foreground: '#ffffff',
                },
                [Package.State.Rejected]: {
                    background: '#81413e',
                    foreground: '#ffffff',
                },
            },
            dashboard_columns: {
                locale_keys: [
                    '',
                    'TITLE',
                    'STATUS',
                    'EXPIRES',
                    'GROSS_PRICE',
                    'NET_PRICE',
                    'AVAILABILITY',
                    'IMPRESSIONS',
                    'CLIENT',
                    'DATE_MODIFIED',
                    '',
                ],
                model_accessors: [
                    'is_public',
                    'name',
                    'state',
                    'expires',
                    'grossPrice',
                    'netPrice',
                    'availability',
                    'impressions',
                    'clientName',
                    'modified',
                    'actions',
                ],
            },
        },
        plan: {
            realtime: {
                enable: true,
                reaction_in_seconds: 5,
            },
            dashboard_columns: {
                locale_keys: ['TITLE', 'PANELS', 'IMPRESSIONS', 'DATE_MODIFIED'],
                model_accessors: [
                    'name',
                    'count',
                    'impressions',
                    'created',
                    'modified',
                ],
            },
            save_brand: true,
            save_client: true,
            enable_quick_access_to_visibility: true,
        },
        assets: {
            exclude: false,
        },
        widgets: {
            show_button_tooltip_as_copies: false,
        },
        proximity: {
            max_zip_codes_per_file: 1000,
            multicolor: false,
            ramp_color: [
                '#5F4690',
                '#1D6996',
                '#38A6A5',
                '#0F8554',
                '#73AF48',
                '#EDAD08',
                '#E17C05',
                '#CC503E',
                '#94346E',
                '#6F4070',
                '#994E95',
            ],
            others_color: '#666666',
            default_color: '#b08527',
        },
        map: {
            layers_precedence: AllLayersPrecedence,
            asset_details_over_map: true,
            fixed_cluster_size: -1,
            zoom_hide_cluster: 8,
            max_bbox: [-180, -90, 180, 90],
            view_state: {
                longitude: 25.3,
                latitude: 65,
                zoom: 5,
                bearing: 0,
                pitch: 0,
            },
            legend: {
                colors: [
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                ],
                enable_product_selection: true,
                default_product_selection: 'l2_product_id',
                exclude_assets: false,
                angle_to_map_north: false,
                facing_direction: false,
                show_geoboundaries_layer: false,
                show_heatmap_layer: false,
                show_pois_layer: false,
                show_product_selection: false,
                title: 'LEGEND',
                show_buffers_visibility_control: false,
                default_buffers_visibility: true,
                show_assets_ids_layer: false,
            },
            highlightSelectedMarker: false,
            screenshot_btn: false,
        },
        audit: {
            enable: false,
        },
        dashboard: {
            show_others_groups_tab: false,
            show_public_plans_by_country_and_user: false,
        },
        share: {
            enable: {
                dev: true,
                staging: true,
                prod: true,
            },
            retrieve_in_blocks_of: 5000,
            expiracyOffset: 60 * 60 * 24 * 365 * 1000,
            password: true,
            package: {
                showPrice: true,
            },
            export_enabled: true,
            password_is_required: false,
            expiracy: {
                enabled: false,
                defaultExpirationInDays: 30,
            },
            feedback_email: 'campaigns@clearchannel.fi',
        },
        new_plan: {
            enable: true,
        },
        save_plan: {
            enable_save_as: false,
            enable: true,
        },
        export: {
            enable: true,
            include_total_assets_column: false,
            add_tab_xlsx_pois: false,
            add_tab_xlsx_proximity: false,
            fileColumnTitles: [
                Export.FilePOIColumns.Id,
                Export.FilePOIColumns.Name,
                Export.FilePOIColumns.Distance,
                Export.FilePOIColumns.Address,
                Export.FilePOIColumns.ZipCode,
            ],
            columnsPOIs: [
                'p.geoId as id',
                'p.location_name as name',
                'p.geom',
                'p.street_address address',
                'p.postal_code zip_code',
            ],
            columnsAllPOIs: [
                'COALESCE(id, " ") as id',
                'name',
                'geom',
                'COALESCE(address, " ") as address',
                'COALESCE(CAST(zip_code AS String), " ") as zip_code',
            ],
            columnsCustomPOIs: [
                'COALESCE(geoid, " ") as geoid',
                'name',
                'geom',
                'COALESCE(address, " ") as address',
                '"" as zip_code',
            ],
            fields_mapping: [
                {
                    model: 'assets',
                    modelField: 'internal_panel_id',
                    exportField: 'internal_panel_id',
                },
                {
                    model: 'assets',
                    modelField: 'latitude',
                    exportField: 'latitude',
                },
                { model: 'assets', modelField: 'longitude', exportField: 'longitude' },
                {
                    model: 'assets',
                    modelField: 'address',
                    exportField: 'address',
                },
                {
                    model: 'assets',
                    modelField: 'zip_code_name',
                    exportField: 'postal_code',
                },
                {
                    model: 'assets',
                    modelField: 'city_name',
                    exportField: 'city',
                },
            ],
        },
        insights: {
            enable: false,
            detail: {
                id_field: 'internal_panel_id',
                second_id_field: 'external_panel_id',
                show_image: true,
                show_advanced_address: true,
                show_media_type: true,
                show_audience: false,
            },
            tabs: {
                impressions: true,
                demographics: true,
                top_indexing: true,
                table_view: true,
            },
            features: {
                demographics: {
                    simpliest_chart: 'pie',
                    pre_filter_audiences_classes: false,
                    sort_by_values: false,
                    identify_gender_charts: ['gender'],
                    format: {
                        index: (value) => {
                            return value;
                        },
                        panel: (value) => {
                            return value;
                        },
                        market: (value) => {
                            return value;
                        },
                    },
                },
                table_view: {
                    name: true,
                    market: true,
                    panel: true,
                    index: true,
                },
            },
        },
        manage_pois: {
            enable: false,
            types: {
                coords: true,
                geocoding: true,
            },
            default_type: Poi.CustomPOIsImportMode.Geocode,
            distinguish_between_user_and_admin: true,
            max_custom_pois_per_file: 1000,
            max_custom_pois_geocoding: 1000,
            max_custom_pois_per_file_admin_user: 6000,
            max_custom_pois_geocoding_admin_user: 6000,
            xlsx_template_geocode: {
                template: 'eu_template_custom_pois.xlsx',
                mandatory_fields_in_template: [
                    'name',
                    'address',
                    'city',
                    'postalcode',
                    'country',
                ],
                mandatory_fields_with_data: ['country'],
                at_least_one_field_with_data_within: ['address', 'city', 'postalcode'],
            },
            xlsx_template_coords: {
                template: 'eu_template_custom_pois.xlsx',
                mandatory_fields_in_template: ['name', 'lat', 'lng'],
                mandatory_fields_with_data: ['lat', 'lng'],
                optional_fields: ['name', 'address', 'city', 'postalcode', 'country'],
            },
            xlsx_header_download: [
                'name',
                'address',
                'city',
                'postalcode',
                'country',
                'lat',
                'lng',
            ],
            custom_pois_expiration: {
                enable: false,
                days: 181,
            },
        },
        popup: {
            title_i18n: 'ID',
            title_id_field: 'internal_panel_id',
            subtitle_id_field: '',
            show_picture: true,
            show_address: true,
            show_coordinates: true,
            show_angle_to_map_north: false,
            show_facing_direction: false,
            show_streetview: true,
            show_panel_name: false,
            show_type: true,
            show_impressions: true,
            show_audiences: false,
            show_exclude: false,
            show_details: true,
            shared_plan_show_details: false,
            show_isoline: false,
        },
        popup_minimal: {
            title_i18n: 'externalpanelid',
            title_id_field: 'external_panel_id',
            subtitle_id_field: '',
            show_picture: true,
            show_address: true,
            show_coordinates: true,
            show_angle_to_map_north: false,
            show_facing_direction: false,
            show_streetview: true,
            show_panel_name: false,
            show_type: false,
            show_impressions: true,
            show_audiences: false,
            show_exclude: false,
            show_details: false,
            shared_plan_show_details: false,
            show_isoline: false,
        },
        popup_share: {
            title_i18n: 'externalpanelid',
            title_id_field: 'external_panel_id',
            subtitle_id_field: '',
            show_picture: true,
            show_address: true,
            show_coordinates: true,
            show_angle_to_map_north: false,
            show_facing_direction: false,
            show_streetview: true,
            show_panel_name: false,
            show_type: true,
            show_impressions: true,
            show_audiences: false,
            show_exclude: false,
            show_details: false,
            shared_plan_show_details: false,
            show_isoline: false,
        },
        help: {
            enable: false,
            url: '',
        },
        multiTabsFilters: {
            enable: false,
            max_tabs: 3,
        },
    },
    plan_filters: {
        widget_countries: {
            enable: false,
            default_selection_iso3: ['FIN'],
        },
        widget_assets: {
            enable: true,
            enable_only_admin: true,
            xlsx_column_name: 'internal_panel_id',
            example_file: 'fi_template_panel_ids.xlsx',
            upload_by_file: true,
            upload_by_paste: false,
            default_option: 'file',
            upload_inventory_permit_excluded: false,
        },
        widget_geoboundaries: {
            enable: false,
            permit_exclude: true,
            custom_drawing: {
                enable: true,
                polygon: true,
                circle: true,
                route: false,
            },
            sample_files: [{ name: 'Findland', link: 'postal_codes_FI.xlsx' }],
        },
        widget_audiences: {
            enable: { dev: false, staging: false, prod: false },
            show_delivery_selector: { dev: true, staging: true, prod: true },
            version: Audience.AudienceFeatureVersion.V2,
            delivery_human_representation: Metadata.DELIVERY_HUMAN_REPRESENTATION.Week,
            max_AND: 10,
            max_OR: 10,
            by_index: {
                name: 'index',
                enable: false,
                max_value: 1000,
                default_min_value: 100,
                default_max_value: 800,
            },
            by_percentage: {
                name: 'percent',
                enable: true,
                selected_min_value: 90,
                selected_max_value: 100,
            },
        },
        widget_media_type: {
            enable: false,
            clear_action_acts_on_digital_filter: false,
            version: Media.MediaTypeFeatureVersion.V2,
            filter_digital: true,
            types: {
                l1panelclass: 'l1panelclass_id',
                l2product: 'l2product_id',
                l3subproduct: 'l3subproduct_id',
                l4: 'l4_id',
                l5: 'l5_id',
                l6: 'l6_id',
                l7: 'l7_id',
                l8: 'l8_id',
                l9: 'l9_id',
            },
            intelligent_digital_static_filter: false,
        },
        widget_list_assets: {
            enable: false,
            fields: ['internal_panel_id', 'name', 'impressions'],
            sortBy: 'name',
            add_copy_btn: true,
            switch_to_list_view: false,
        },
        widget_optimize_distribution: {
            enable: false,
            disable_production: false,
            types: [
                Metadata.TYPES_OPTIMIZE_DISTRIBUTION.Impressions,
                Metadata.TYPES_OPTIMIZE_DISTRIBUTION.Audience,
            ],
            prioritary_regions: [1, 2, 3, 4, 5, 6, 7, 8],
            excluded_regions: [15],
            max_number_assets: {
                enable: true,
                value: 10,
            },
            min_distance_between_assets: {
                enable: true,
                units: 'm',
                max_distance: 400,
                selected_distance: 7,
                num_elements_direct_query: 10000,
            },
        },
        widget_proximity: {
            enable: false,
            version: Proximity.ProximityFeatureVersion.V2,
            modal: {
                show_brands: true,
                show_custom_pois: true,
                show_tags: true,
                show_locations: true,
                search_by: ['categories', 'brands', 'custom pois', 'tags', 'locations'],
            },
            pois_isochrones_fields: ['geoid', 'country_id', 'geom'],
            types: {
                radius: {
                    id: 'RADIUS',
                    name: 'RADIUS',
                    enable: true,
                    units: 'meters',
                    min_distance: 0,
                    max_distance: 10000,
                    double_buffer: true,
                    selected_min_distance: 0,
                    selected_max_distance: 1000,
                    max_assets_per_poi: {
                        enable: true,
                        options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    },
                },
                driving: {
                    id: 'DRIVING',
                    name: 'DRIVING',
                    enable: true,
                    options: [300, 900, 1800, 3600],
                    selected: 300,
                },
                walking: {
                    id: 'WALKING',
                    name: 'WALKING',
                    enable: true,
                    options: [300, 900, 1800, 3600],
                    selected: 300,
                },
            },
            max_isochrones: 15000,
            show_modal_warning_credits: false,
        },
        widget_packages: {
            enable: true,
            price_range: [0, 150000],
        },
    },
};
