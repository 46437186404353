export var AuditEventType;
(function (AuditEventType) {
    AuditEventType["Login"] = "login";
    AuditEventType["LoginFirstTime"] = "login_first_time";
    AuditEventType["PlanCreated"] = "plan_created";
    AuditEventType["PlanSaved"] = "plan_saved";
    AuditEventType["PlanOpened"] = "plan_opened";
    AuditEventType["PlanDeleted"] = "plan_deleted";
    AuditEventType["PlanShared"] = "plan_shared";
    AuditEventType["PlanInsights"] = "plan_insights";
    AuditEventType["PlanExported"] = "plan_exported";
    AuditEventType["PlanLoaded"] = "plan_loaded";
    AuditEventType["PlanLoadedUsage"] = "plan_loaded_usage";
    AuditEventType["BasemapChangedToGoogle"] = "basemap_changed_to_google";
})(AuditEventType || (AuditEventType = {}));
