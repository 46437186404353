import { Auth } from '@workspaces/types';
const TestingUsers = new Map([
    ['ps-radarview-us+test@cartodb.com', { role: Auth.Role.User }],
    ['ps-radarview+test_admin@cartodb.com', { role: Auth.Role.Admin }],
    ['ps-radarview+test_power@cartodb.com', { role: Auth.Role.PowerUser }],
    ['ps-radarview+test_general@cartodb.com', { role: Auth.Role.User }],
    ['ps-radarview-fi+test_admin@cartodb.com', { role: Auth.Role.Admin }],
    ['ps-radarview-fi+test@cartodb.com', { role: Auth.Role.User }],
]);
export function getRoleForUser(user) {
    const userProperties = TestingUsers.get(user);
    if (!userProperties) {
        throw new Error(`User ${user} not found in testing users`);
    }
    return userProperties.role;
}
