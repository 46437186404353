import * as XLSX from 'xlsx';
import { BREAKLINE, DELIMITER } from './export.constants';
import { isStringFloat, isStringInteger } from '@/helpers/common.helper';
import { removeUnwantedCharsForCVS } from '@/utils/escape';
export const convertCsvToExcelBuffer = (csvString) => {
    const arrayOfArrayCsv = csvString.split(BREAKLINE).map((row) => {
        return row.split(',');
    });
    const workBook = XLSX.utils.book_new();
    const newWorkSheet = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
    XLSX.utils.book_append_sheet(workBook, newWorkSheet);
    const rawExcel = XLSX.write(workBook, { type: 'base64', bookType: 'xlsx' });
    return rawExcel;
};
export const convertCsvToExcelFile = (csvFilesOpts, filename) => {
    const workBook = XLSX.utils.book_new();
    csvFilesOpts.forEach((csvFileOpts) => {
        const arrayOfArrayCsv = csvFileOpts.csvString
            .split(BREAKLINE)
            .map((row) => {
            return row.split(DELIMITER);
        });
        const newWorkSheet = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
        XLSX.utils.book_append_sheet(workBook, newWorkSheet, csvFileOpts.tabName);
        if (csvFileOpts.applyNumberFormat) {
            // const integerFormat = '#,##0'
            // const decimalFormat = '#,##0.00'
            const content = newWorkSheet['!ref'];
            if (!content) {
                throw new Error('Cannot export content to file. WorkSheetRef is not defined');
            }
            const range = XLSX.utils.decode_range(content);
            for (let R = range.s.r + 1; R <= range.e.r; ++R) {
                for (let C = range.s.c; C <= range.e.c; ++C) {
                    if (csvFileOpts.columnsToDoNotApplyNumberFormat.includes(C)) {
                        continue;
                    }
                    const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                    const cell = newWorkSheet[cellRef];
                    const cellAsString = String(cell.v);
                    if (cellAsString.startsWith('0')) {
                        if (!(cellAsString === '0' ||
                            cellAsString.startsWith('0.') ||
                            cellAsString.startsWith('0,'))) {
                            cell.v = cellAsString;
                            continue;
                        }
                    }
                    if (isStringFloat(cell.v)) {
                        cell.v = parseFloat(cell.v);
                        // cell.z = decimalFormat
                        cell.t = 'n';
                    }
                    else if (isStringInteger(cell.v)) {
                        cell.v = parseInt(cell.v, 10);
                        // cell.z = integerFormat
                        cell.t = 'n';
                    }
                }
            }
        }
    });
    XLSX.writeFile(workBook, filename, { type: 'base64', bookType: 'xlsx' });
};
export const convertJsonToExcelFile = (json, filename) => {
    const workBook = XLSX.utils.book_new();
    const newWorkSheet = XLSX.utils.json_to_sheet(json);
    XLSX.utils.book_append_sheet(workBook, newWorkSheet);
    XLSX.writeFile(workBook, filename, { type: 'base64', bookType: 'xlsx' });
};
export const convertExcelBufferToJsonFile = (fileBuffer) => {
    if (!fileBuffer) {
        return null;
    }
    const workBook = XLSX.read(fileBuffer, { type: 'buffer' });
    const workBookName = workBook.SheetNames[0];
    const workSheet = workBook.Sheets[workBookName];
    return XLSX.utils.sheet_to_json(workSheet);
};
export const convertExcelBufferToCSVFile = (fileBuffer, filename) => {
    if (!fileBuffer) {
        return null;
    }
    const workBook = XLSX.read(fileBuffer, { type: 'buffer' });
    const workBookName = workBook.SheetNames[0];
    const workSheet = workBook.Sheets[workBookName];
    const csvData = XLSX.utils.sheet_to_csv(workSheet, { blankrows: false });
    const file = new File([csvData], filename, {
        type: 'text/csv',
    });
    let length = 0;
    try {
        const workSheetRef = workSheet['!ref'];
        if (!workSheetRef) {
            throw new Error('Cannot export contet to file. WorkSheetRef is not defined');
        }
        const range = XLSX.utils.decode_range(workSheetRef);
        length = range ? range.e.r : 0;
    }
    catch (e) { }
    return {
        file,
        length,
    };
};
export const convertExcelBufferToCSV = (fileBuffer, separator = ',') => {
    if (!fileBuffer) {
        return null;
    }
    const workBook = XLSX.read(fileBuffer, { type: 'buffer' });
    const workBookName = workBook.SheetNames[0];
    const workSheet = workBook.Sheets[workBookName];
    const csvData = XLSX.utils.sheet_to_csv(workSheet, {
        FS: separator,
        blankrows: false,
    });
    return csvData;
};
export const convertExcelBufferToCSVViaJson = (fileBuffer, expectedColumns, separator = ',', content = '') => {
    const jsonContent = convertExcelBufferToJsonFile(fileBuffer);
    if (!jsonContent) {
        return null;
    }
    let jsonKeys = Object.keys(jsonContent[0]);
    if (expectedColumns.length > 0) {
        jsonKeys = expectedColumns;
    }
    const csvContent = jsonContent.map((row) => {
        const rowFormatted = jsonKeys.map((key) => {
            const cellContent = row[key];
            if (cellContent) {
                const cellContentFormatted = removeUnwantedCharsForCVS(cellContent);
                return cellContentFormatted;
            }
            return cellContent;
        });
        return rowFormatted.join(separator);
    });
    const csvHeader = jsonKeys.join(separator);
    csvContent.unshift(csvHeader);
    return csvContent.join('\n');
};
