// code adapted from here: https://github.com/visgl/deck.gl/blob/8.1-release/modules/core/bundle/deckgl.js
// but removing the dependency on the 'react-map-gl' component

import { Deck } from '@deck.gl/core'
import mapboxgl from 'mapbox-gl'
import { GoogleMapsOverlay } from '@deck.gl/google-maps'
import { getGoogleMapInstance } from './google/google-maps.helper'
import { Basemap } from '@workspaces/types'
import { isGoogleMapsBasemap } from '@/helpers/google-maps.helper'
import { manageAssetsLayersVisibility } from '@/helpers/map.helper'
import { getAppMetadata } from '@/plan/metadata/metadata'
import { getZoomLevelForDeclustering } from '@/plan/metadata/metadata.helper'

export const ZOOM_TRANSITION_DURATION = 250

const CANVAS_STYLE = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

const DEFAULT_MAP_PROPS = {
  layers: [],
  basemap: null,
  controller: { scrollZoom: { smooth: true, speed: ZOOM_TRANSITION_DURATION } },
  useDevicePixels: 2,
  getCursor: ({ isDragging }) => (isDragging ? 'grabbing' : 'pointer'),
  getTooltip({ x, y, object, layer }) {
    if (!object) {
      return
    }
    const tooltipFn = layer.props.getTooltip
    if (typeof tooltipFn === 'function') {
      return tooltipFn({ object, layer, x, y })
    }
  },
  layerFilter({ layer, viewport }) {
    const filterFn = layer.props.layerFilter
    if (typeof filterFn === 'function') {
      return filterFn(viewport, layer)
    }
    return true
  },
}

// Create canvas elements for mapbox and deck
function createCanvas(props) {
  let { container = document.body } = props

  if (typeof container === 'string') {
    container = document.querySelector(container)
  }

  if (!container) {
    throw new Error('[DeckMap] container not found')
  }

  const containerStyle = window.getComputedStyle(container)
  if (containerStyle.position === 'static') {
    container.style.position = 'relative'
  }

  const mapboxCanvas = document.createElement('div')
  container.appendChild(mapboxCanvas)
  Object.assign(mapboxCanvas.style, CANVAS_STYLE)

  const deckCanvas = document.createElement('canvas')
  deckCanvas.oncontextmenu = () => false // this disables right click on map
  container.appendChild(deckCanvas)
  Object.assign(deckCanvas.style, CANVAS_STYLE)

  return { container, mapboxCanvas, deckCanvas }
}

/**
 * @params container (Element) - DOM element to add deck.gl canvas to
 * @params map (Object) - map API. Set to falsy to disable
 */
export default class DeckMap extends Deck {
  appBasemap = Basemap.Basemap.OpenStreetMaps
  currentBoundingBox = null
  mapRef = null

  constructor(props = {}) {
    props = {
      ...DEFAULT_MAP_PROPS,
      ...props,
    }

    if (props.appBasemap === Basemap.Basemap.OpenStreetMaps) {
      props.onResize = () => {
        if (this._map) {
          this._map.resize()
        }
      }
    }

    const { mapboxCanvas, deckCanvas } = createCanvas(props)

    super({ canvas: deckCanvas, ...props })

    const viewState = props.viewState || props.initialViewState
    this.appBasemap = props.basemap

    if (this.appBasemap === Basemap.Basemap.OpenStreetMaps) {
      this._map = new mapboxgl.Map({
        container: mapboxCanvas,
        style: props.mapStyle,
        interactive: false,
        center: [viewState.longitude, viewState.latitude],
        zoom: viewState.zoom,
        bearing: viewState.bearing || 0,
        pitch: viewState.pitch || 0,
      })
    } else {
      this._map = null
    }

    if (this.appBasemap === Basemap.Basemap.OpenStreetMaps) {
      // Update base map
      this._onBeforeRender = (params) => {
        this.onBeforeRender(params)
        if (this._map) {
          const viewport = this.getViewports()[0]
          this._map.jumpTo({
            center: [viewport.longitude, viewport.latitude],
            zoom: viewport.zoom,
            bearing: viewport.bearing,
            pitch: viewport.pitch,
          })
          // TODO: only redraw when viewport has changed
          this.redrawMapbox()
        }
      }
    }
  }

  setMapRef(mapRef) {
    this.mapRef = mapRef
  }

  getAppBasemap() {
    return this.appBasemap
  }

  updateCurrentBoundingBox(center, zoom) {
    const provider = this.appBasemap
    this.currentBoundingBox = {
      center,
      zoom,
      provider,
    }
    this.mapRef?.onUpdateViewState(this.currentBoundingBox)
  }

  getCurrentBoundingBox() {
    return this.currentBoundingBox
  }

  isAppBasemapGoogleMaps() {
    return isGoogleMapsBasemap(this.appBasemap)
  }

  getBaseMap() {
    return this._map
  }

  async setGoogleMapsBaseMap(layers, style, viewState) {
    this.appBasemap = style
    const viewStateParams = Object.keys(viewState).length > 0 ? viewState : null
    const map = await getGoogleMapInstance(this.appBasemap, viewStateParams)
    const googleMaps = new GoogleMapsOverlay({ layers })
    googleMaps.setMap(map)
    this._map = googleMaps

    map.addListener('idle', () => {
      this.handleViewStateChange(map)
    })
  }

  handleViewStateChange(map) {
    const center = map.getCenter()
    const centerFormatted = [center.lng(), center.lat()]
    const zoom = map.getZoom()

    this.updateCurrentBoundingBox(centerFormatted, zoom)

    // console.debug('🌎 DECK: ViewState changed:', {
    //   center: centerFormatted,
    //   zoom,
    // })

    const metadata = getAppMetadata()
    manageAssetsLayersVisibility(getZoomLevelForDeclustering(metadata), zoom, {
      zoom,
    })
  }

  // code taken from here: https://github.com/visgl/react-map-gl/blob/ce6f6662ca34f8765cf0f515039e316adb52a957/src/mapbox/mapbox.js#L421
  // Force redraw the map now. Typically resize() and jumpTo() is reflected in the next
  // render cycle, which is managed by Mapbox's animation loop.
  // This removes the synchronization issue caused by requestAnimationFrame.
  redrawMapbox() {
    const map = this._map
    // map._render will throw error if style does not exist
    // https://github.com/mapbox/mapbox-gl-js/blob/fb9fc316da14e99ff4368f3e4faa3888fb43c513/src/ui/map.js#L1834
    if (map.style) {
      // cancel the scheduled update
      if (map._frame) {
        map._frame.cancel()
        map._frame = null
      }
      // the order is important - render() may schedule another update
      map._render()
    }
  }

  getMapboxMap() {
    return this._map
  }

  finalize() {
    const prefixMsg = '🌎 DECK: Finalizing DeckMap instance'
    if (this.isAppBasemapGoogleMaps()) {
      console.debug(prefixMsg, ' with basemap Google Maps')
      this._map.setMap(null)
    } else {
      if (this._map) {
        console.debug(prefixMsg, ' with basemap OpenStreetMaps')
        this._map.remove()
      }
    }
    super.finalize()
  }

  setProps(props) {
    // Replace user callback with our own
    // `setProps` is first called in parent class constructor
    // During which this._onBeforeRender is not defined
    // It is called a second time in _onRendererInitialized with all current props
    if (
      'onBeforeRender' in props &&
      this._onBeforeRender &&
      props.onBeforeRender !== this._onBeforeRender
    ) {
      this.onBeforeRender = props.onBeforeRender
      props.onBeforeRender = this._onBeforeRender
    }

    super.setProps(props)
  }
}
