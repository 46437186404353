import { getAppMetadata } from '@/plan/metadata/metadata';
import { getPermissionResolverVersion } from '@/plan/metadata/metadata.helper';
import { Auth } from '@workspaces/types';
import PermissionResolverEU from './resolvers/resolver-eu';
import PermissionResolverFI from './resolvers/resolver-fi';
import PermissionResolverUS from './resolvers/resolver-us';
class UserPermissionManager {
    static instance;
    permissionResolver;
    constructor(userInformation) {
        const meta = getAppMetadata();
        // Get the correspondin message resolver based on metadata
        const permissionResolverVersion = getPermissionResolverVersion(meta);
        switch (permissionResolverVersion) {
            case Auth.PermissionResolver.US:
                this.permissionResolver = new PermissionResolverUS(userInformation);
                break;
            case Auth.PermissionResolver.EU:
                this.permissionResolver = new PermissionResolverEU(userInformation);
                break;
            case Auth.PermissionResolver.FI:
                this.permissionResolver = new PermissionResolverFI(userInformation);
                break;
            default:
                throw new Error(`Unknown permission resolver version: ${permissionResolverVersion}`);
        }
    }
    static initialize(userInformation) {
        UserPermissionManager.instance = new UserPermissionManager(userInformation);
        return UserPermissionManager.instance;
    }
    static getInstance() {
        if (!UserPermissionManager.instance) {
            throw new Error('UserPermissionManager is not initialized. Must be initialized before using it');
        }
        return UserPermissionManager.instance;
    }
    canUploadCustomPOIs() {
        return this.permissionResolver.canUploadCustomPOIs();
    }
    canEditAnyCustomPOIGroup() {
        return this.permissionResolver.canEditAnyCustomPOIGroup();
    }
    permitComments(planOwner) {
        return this.permissionResolver.permitComments(planOwner);
    }
    canSavePlan(planOwner, planId, isPlanPublic) {
        return this.permissionResolver.canSavePlan(planOwner, planId, isPlanPublic);
    }
    canSavePlanAsPublic() {
        return this.permissionResolver.canSavePlanAsPublic();
    }
    canDeletePlan(plan) {
        return this.permissionResolver.canDeletePlan(plan);
    }
    canDeleteCustomGeoboundary(owner) {
        return this.permissionResolver.canDeleteCustomGeoboundary(owner);
    }
    canDeleteCustomPOIGroup(owner) {
        return this.permissionResolver.canDeleteCustomPOIGroup(owner);
    }
    canUpdateCustomPOIGroup(owner) {
        return this.permissionResolver.canUpdateCustomPOIGroup(owner);
    }
    canAdminEditPlan(plan) {
        return this.permissionResolver.canAdminEditPlan(plan);
    }
    canEditPlanPrice(plan) {
        return this.permissionResolver.canEditPlanPrice(plan);
    }
    canBookPlanPackage(plan) {
        return this.permissionResolver.canBookPlanPackage(plan);
    }
    canConfirmBookingPlanPackageByAdmin(plan) {
        return this.permissionResolver.canConfirmBookingPlanPackageByAdmin(plan);
    }
    canConfirmBookingPlanPackageByAgency(plan) {
        return this.permissionResolver.canConfirmBookingPlanPackageByAgency(plan);
    }
    canFinalizeConfirmBookingPlanPackageByAdmin(plan) {
        return this.permissionResolver.canFinalizeConfirmBookingPlanPackageByAdmin(plan);
    }
    canCancelPlanPackageByAdmin(plan) {
        return this.permissionResolver.canCancelPlanPackageByAdmin(plan);
    }
    canShowWidgetUploadAssets() {
        return this.permissionResolver.canShowWidgetUploadAssets();
    }
    canEditPlanPackageDates(plan) {
        return this.permissionResolver.canEditPlanPackageDates(plan);
    }
    canEditPlanPackagePrice(plan) {
        return this.permissionResolver.canEditPlanPackagePrice(plan);
    }
    canEditPlanPackageCustomPanels(plan) {
        return this.permissionResolver.canEditPlanPackageCustomPanels(plan);
    }
    canEditPlanPackagePanelTypes(plan) {
        return this.permissionResolver.canEditPlanPackagePanelTypes(plan);
    }
    canEditPlanPackagePackages(plan) {
        return this.permissionResolver.canEditPlanPackagePackages(plan);
    }
}
export default UserPermissionManager;
